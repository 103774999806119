.App {
  text-align: center;
}

.App-logo {
  height: 60vh;
  pointer-events: none;
}
.App-text{
  color: #346d7d;
  font-size: 2rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 ;
  padding: 10px 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
